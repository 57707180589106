import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import masterLayout from '@/layout/masterLayout.vue'; //引入后台布局
import { getLocalStorage } from '@/utils/localStorage';
import { useStore } from 'vuex';
import { computed } from 'vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/admin/home',
	},
	{
		path: '/admin/home',
		name: 'master',
		component: masterLayout,
		children: [
			{
				path: '/admin/home',
				name: 'Home',
				component: () => import('@/views/admin/home/index.vue'),
				meta: {
					authentication: true,
					keepAlive: true,
					title: '首页',
					name: 'Home',
				},
			},
		],
	},
	{
		path: '/admin/login',
		name: '/admin/login',
		component: () => import('../views/admin/login/index.vue'),
	},
	{
		path: '/material/material-offer/edit',
		name: 'materialOfferEdit',
		component: () => import('../views/material/material-offer/edit.vue'),
	},
	// {
	// 	path: '/material/material-offer/edit2',
	// 	name: 'materialOfferEdit2',
	// 	component: () => import('../views/material/material-offer/edit2.vue'),
	// },
	{
		path: '/:catchAll(.*)',
		component: masterLayout,
		children: [
			{
				path: '/:catchAll(.*)',
				name: '404',
				component: () => import('@/views/404/index.vue'),
				meta: {
					authentication: true,
					keepAlive: false,
				},
			},
			// {
			//   path: '/supplier/supplier-price/index',
			//   name: '_supplier_supplier-price_index',
			//   component: () => import('@/views/supplier/supplier-price/index.vue'),
			//   meta: {
			//     authentication: true,
			//     keepAlive: true,
			//     title: '物料报价',
			//     name: '_supplier_supplier-price_index'
			//   }
			// },
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});

let isSetRouter = true;
//路由前置方法
router.beforeEach((to, from, next) => {
	const { authentication } = to.meta;
	if (authentication && !getLocalStorage('Authorization')) {
		next({
			path: '/admin/login',
		});
	}
	//得到后台返回的router
	const store = useStore();
	const userinfo = computed(() => store.state.user.userinfo);
	if (isSetRouter && userinfo.value.router.length != 0) {
		userinfo.value.router.forEach((element: any) => {
			router.addRoute('master', {
				path: element.path,
				name: element.name,
				component: () => import('@/views' + element.path + '.vue'),
				meta: {
					authentication: true,
					keepAlive: true,
					title: element.title,
					name: element.name,
				},
			});
			isSetRouter = false; //将isSetRouter赋为 false ，否则会一直循环，崩溃
			// 新增加的路由与当前位置相匹配，就需要你用 router.push() 或 router.replace() 来手动导航
			router.replace(to.fullPath);
		});
	} else {
		next();
	}
});

export default router;
