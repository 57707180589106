export default {
	material_offer_edit: {
		are_you_sure_to_batch_delete_the_materials:
			'Are you sure to batch delete the materials you have selected?',
		are_you_sure_to_batch_update_the_materials:
			'Are you sure to batch update the materials you have selected?',
		are_the_selected_materials_marked_as_matching_correctly:
			'Are the selected materials marked as matching correctly?',
		are_the_selected_materials_notified_to_the_buyer:
			'Are the selected materials notified to the buyer?',
		please_check_if_the_materials_in_the_quotation_are_what_you_need:
			'Please check if the materials in the quotation are what you need.',
		view_operation_prompts: 'View Operation Prompts',
		if_they_are_not_what_you_need_you_can_delete_them_directly_or_contact_the_sales_manager_for_assistance_in_making_corrections:
			'If they are not what you need, you can delete them directly or contact the sales manager for assistance in making corrections.',
		please_confirm_whether_the_quantity_and_price_in_the_quotation_are_correct:
			'Please confirm whether the quantity and price in the quotation are correct.',
		you_can_directly_modify_the_purchase_quantity_of_each_item_based_on_your_actual_needs:
			'You can directly modify the purchase quantity of each item based on your actual needs.',
		please_select_the_materials_that_need_to_be_deleted:
			'Please select the materials that need to be deleted in bulk!',
		please_select_the_materials_that_require_batch_setting_of_interest_rates:
			'Please select the materials that require batch setting of interest rates!',
		please_select_the_materials_that_require_batch_setting_of_quantity:
			'Please select the materials that require batch setting of quantity!',
		if_you_confirm_that_all_the_information_in_the_quotation_is_correct_click_the_save_button:
			'If you confirm that all the information in the quotation is correct, click the save button.',
		after_clicking_the_save_button_the_sales_manager_will_see_the_updated_quotation:
			'After clicking the save button, the sales manager will see the updated quotation. ',
		please_select_the_materials_that_require_batch_operation:
			'Please select the materials that require batch operation!',
		if_you_have_any_questions_regarding_the_operation_feel_free_to_contact_the_sales_manager_you_are_working_with_at_any_time:
			'If you have any questions regarding the operation, feel free to contact the sales manager you are working with at any time.',
		import_exception_please_try_again: 'Import Exception, Please Try Again!',
		please_enter_the_quantity: 'Please Enter The Quantity',
		all_materials: 'All Materials',
		kind_reminder: 'Kind Reminder',
		matched_materials: 'Matched Materials',
		unmatched_materials: 'Unmatched Materials',
		materials_to_be_verified: 'Materials To Be Verified',
		lock_the_quotation: 'Lock The Quotation',
		enter_keywords: 'Enter Keywords',
		enter_material_code: 'Enter Material Code',
		batch_set_quantity: 'Batch Set Quantity',
		customer_material_code: 'Customer Material Code',
		customer_inquiry_name: 'Customer Inquiry Name',
		brand: 'Brand',
		model: 'Model',
		material_name: 'Material Name',
		material_description: 'Material Description',
		quality: 'Quality',
		colour: 'Colour',
		version: 'Version',
		quantity: 'Quantity',
		edit: 'Edit',
		cancel: 'Cancel',
		delete: 'Delete',
		batch_delete: 'Batch Delete',
		batch_renewal_cost_price: 'Batch Renewal Const Price',
		batch_labeling_is_correct: 'Batch Labeling Is Correct',
		batch_urge_quotation: 'Batch Urge Quotation',
		share_with_customers: 'Share With Customers',
		back: 'Back',
		save: 'Save',
		saved: 'Saved',
		action: 'Action',
		correct: 'Correct',
		confirm: 'Confirm',
		download_quotation: 'Download Quotation',
		please_enter_search_keywords: 'Please Enter Search Keywords',
		required_materials_by_customer: 'Required Materials by Customer',
		customer_has_deleted_material: 'The customer has deleted the material',
		generate_pi_list: 'Generate PI List',
		add_quotation_items: 'Add Quotation Items',
		bulk_set_interest_rates: 'Bulk Set Interest Rates',
		inquiry_name: 'Inquiry Name',
		cost: 'Cost',
		interest_rate: 'Interest Rate',
		exchange_rate: 'Exchange Rate',
		material_code: 'Material Code',
		english_name: 'English Name',
		chinese_name: 'Chinese Name',
		series: 'Series',
		please_choose: 'Please Choose',
		choose: 'Choose',
		query: 'Query',
		reset: 'Reset',
		confirm_the_addition: 'Confirm The Addition',
		please_enter: 'Please Enter',
		interest_rate_cannot_be_lower_than_001: 'The interest rate cannot be lower than 0.01',
		interest_rate_cannot_exceed_1: 'The interest rate cannot exceed 1',
		quantity_cannot_be_less_than_1: 'The quantity cannot be less than 1',
		quantity_cannot_exceed_99999: 'The quantity cannot exceed 99999',
		please_enter_a_valid_number: 'Please enter a valid number',
		newly_added: 'Newly Added',
		main_image: 'Main Image',
		copy: 'Copy',
		remarks: 'Remarks',
		new_cost_price: 'New Cost Price',
		cost_price: 'Cost Price',
		sale_price: 'Sale Price',
		gross_margin: 'Gross Margin',
		whole: 'Whole',
		please_add_materials_first: 'Please add materials first',
		customer_materials: 'Customer Materials',
		correct_data: 'Correct Data',
		material_code_must_be_filled_in: 'Material code must be filled in',
		classification: 'Classification',
		product_name: 'Product Name',
		chinese_product_name: 'Chinese Product Name',
		english_product_name: 'English Product Name',
		describe: 'Describe',
		to_b_interest_rate: 'To B interest rate',
		to_b_sales_price: 'To B sales price',
		create_time: 'Create Time',
		quotation_time: 'Quotation Time',
		choice: 'Choice',
		please_choose_a_category: 'Please choose a category',
		please_select_the_model: 'Please select the model',
		add_quotation_data: 'Add quotation data',
		title_name: 'Title Name',
		code: 'Code',
		image_loading_failed: 'Image loading failed',
		batch_setting: 'Batch setting',
		success: 'Success',
		failed: 'Failed',
		ok: 'Ok',
		price: 'Price',
		minimum_price: 'Minimum Price',
		highest_price: 'Highest price',
		price_range: 'Price Range',
		to: 'to',
		successful_notification: 'Successful Notification',
		failure_notification: 'Notification failure',
	},
};
